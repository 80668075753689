@font-face {
  font-family: "MaisonNeue-Medium";
  src: url("../assets/fonts/MaisonNeue-Medium.woff2") format("woff2"),
    url("../assets/fonts/MaisonNeue-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeue-Demi";
  src: url("../assets/fonts/MaisonNeue-Demi.woff2") format("woff2"),
    url("../assets/fonts/MaisonNeue-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeue-Bold";
  src: url("../assets/fonts/MaisonNeue-Bold.woff2") format("woff2"),
    url("../assets/fonts/MaisonNeue-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeueMono-Regular";
  src: url("../assets/fonts/MaisonNeueMono-Regular.woff2") format("woff2"),
    url("../assets/fonts/MaisonNeueMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
